import {
  Stack,
  IconButton,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../../../../context/firebase/AuthContext";
import { useEffect, useState } from "react";
import { usePoopService } from "../../../../../context/firebase/PoopContext";
import { Poop } from "../../../../../firebase/types";
import { useToast } from "../../../../../context/ToastContext";
import { baseUrl } from "../../../../../global/utils";
import { LoadingButton } from "@mui/lab";

const BRISTOL_TYPES = [
  "Type 1",
  "Type 2",
  "Type 3",
  "Type 4",
  "Type 5",
  "Type 6",
  "Type 7",
  "Other",
  "Indecise",
];

const imageMap: Record<string, string> = {
  "Type 1": require("../../../../../assets/types/Type 1.png"),
  "Type 2": require("../../../../../assets/types/Type 2.png"),
  "Type 3": require("../../../../../assets/types/Type 3.png"),
  "Type 4": require("../../../../../assets/types/Type 4.png"),
  "Type 5": require("../../../../../assets/types/Type 5.png"),
  "Type 6": require("../../../../../assets/types/Type 6.png"),
  "Type 7": require("../../../../../assets/types/Type 7.png"),
  Other: require("../../../../../assets/types/Other.png"),
  Indecise: require("../../../../../assets/types/Face.png"),
};

const AccountAdminNewTypePicker = () => {
  const [verifiedPoops, setVerifiedPoops] = useState<number>(0);
  const [poopsToVerify, setPoopsToVerify] = useState<Poop[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string | null>(null);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthService();
  const {
    getPoopsToVerifyFor1,
    getPoopsToVerifyFor2,
    getPoopsToVerifyFor3,
    updateVerify1,
    updateVerify2,
    updateVerify3,
    changePoopType,
    getPoopsVerifiedBy1,
    getPoopsVerifiedBy2,
    getPoopsVerifiedBy3,
  } = usePoopService();
  const { showToast } = useToast();

  useEffect(() => {
    if (!user?.admin) {
      navigate(-1);
    } else {
      getAndSetPoopsNotVerified();
      getNumbers();
    }
  }, [user]);

  const getNumbers = async () => {
    let numberOfPoopsVerified = 0;
    if (user?.email === "91bota@gmail.com") {
      numberOfPoopsVerified = await getPoopsVerifiedBy1();
    } else if (user?.email === "mattygio@hotmail.it") {
      numberOfPoopsVerified = await getPoopsVerifiedBy2();
    } else if (user?.email === "botalla.fabrizio@gmail.com") {
      numberOfPoopsVerified = await getPoopsVerifiedBy3();
    }
    setVerifiedPoops(numberOfPoopsVerified);
  };

  const getAndSetPoopsNotVerified = async () => {
    let poops: Poop[] = [];
    if (user?.email === "91bota@gmail.com") {
      poops = await getPoopsToVerifyFor1();
    } else if (user?.email === "mattygio@hotmail.it") {
      poops = await getPoopsToVerifyFor2();
    } else if (user?.email === "botalla.fabrizio@gmail.com") {
      poops = await getPoopsToVerifyFor3();
    }
    setPoopsToVerify(poops);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleVerify = async () => {
    if (!type) return;
    setLoading(true);
    if (user?.email === "91bota@gmail.com") {
      await updateVerify1(poopsToVerify[0].id, type);
    } else if (user?.email === "mattygio@hotmail.it") {
      await updateVerify2(poopsToVerify[0].id, type);
    } else if (user?.email === "botalla.fabrizio@gmail.com") {
      await updateVerify3(poopsToVerify[0].id, type);
      await checkFinalStep();
    }
    // await changePoopType(poopsToVerify[0].id, type);
    // await saveImageToGoogleDrive();
    setPoopsToVerify((prevPoops) => prevPoops.slice(1));
    setType(null);
    setVerifiedPoops((prev) => prev + 1);
    setLoading(false);
  };

  const checkFinalStep = async () => {
    let chatGptType = poopsToVerify[0].aiScreening?.general?.type; //12.5% weight
    let ourAi = poopsToVerify[0].type; // 12.5% weight
    let verifier1Type = poopsToVerify[0].firstVerification; // 25% weight
    let verifier2Type = poopsToVerify[0].secondVerification; // 25% weight
    let finalType = type; // 25% weight

    let finalTypeScore = 0;
    if (chatGptType === finalType) finalTypeScore += 0.125;
    if (ourAi === finalType) finalTypeScore += 0.125;
    if (verifier1Type === finalType) finalTypeScore += 0.25;
    if (verifier2Type === finalType) finalTypeScore += 0.25;
    if (finalTypeScore >= 0.75) {
      //@ts-ignore
      saveImageToGoogleDrive(type);
      if (type !== "Indecise") {
        //@ts-ignore
        changePoopType(poopsToVerify[0].id, type);
      }
    } else {
      saveImageToGoogleDrive("Indecise");
    }
  };

  const saveImageToGoogleDrive = async (finalType: string) => {
    const functionUrl = `${baseUrl}/saveImageToDrive`;

    try {
      const response = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          downloadUrl: poopsToVerify[0].image,
          type: finalType,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      showToast("Image saved to Google Drive", "success");
    } catch (error) {
      showToast(`Something went wrong: ${error}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const currentPoop = poopsToVerify[0];

  return (
    <Stack spacing={2} width={"100%"} p={2}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <IconButton onClick={handleBack} size="small">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Typography variant="h6" textAlign="center">
          Type Picker
        </Typography>
        <Stack width={50} />
      </Stack>

      <Divider />

      {currentPoop ? (
        <>
          <Stack position="relative" width="100%">
            <img
              src={currentPoop.image}
              alt="Poop to verify"
              style={{
                width: "100%",
                height: 400,
                maxHeight: 400,
                objectFit: "contain",
                borderRadius: 8,
              }}
            />
            <IconButton
              onClick={() => setFullscreenOpen(true)}
              size="small"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Stack>

          <Dialog
            open={fullscreenOpen}
            onClose={() => setFullscreenOpen(false)}
            fullScreen
            maxWidth="xl"
          >
            <DialogContent sx={{ p: 0 }}>
              <IconButton
                onClick={() => setFullscreenOpen(false)}
                size="small"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  },
                }}
              >
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>

              <img
                src={currentPoop.image}
                alt="Fullscreen Poop"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </DialogContent>
          </Dialog>

          <Divider />

          <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
            justifyContent="center"
          >
            {BRISTOL_TYPES.map((t) => (
              <Button
                key={t}
                variant={type === t ? "contained" : "outlined"}
                onClick={() => setType(t)}
                sx={{
                  textTransform: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  padding: 1,
                }}
              >
                <img
                  src={imageMap[t]}
                  alt={t}
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "contain",
                    marginBottom: 4,
                  }}
                />
                {t}
              </Button>
            ))}
          </Stack>

          <LoadingButton
            loading={loading}
            disabled={!type}
            fullWidth
            variant="contained"
            onClick={handleVerify}
          >
            Verify
          </LoadingButton>
          <Typography textAlign="center" mt={2}>
            {verifiedPoops} Poops verified by you.
          </Typography>
        </>
      ) : (
        <Stack alignItems="center" mt={5} spacing={2}>
          <CircularProgress />
          <Typography mt={2} textAlign={"center"}>
            Loading poop to verify... Se ci mette troppo e' perche'
          </Typography>
          <Typography fontSize={14}>
            1- Hai verificato 100 immagini in una tirata -- REFRESH APP
          </Typography>
          <Typography fontSize={14}>
            2- Non ci sono altre immagini da verificare per ora, la persona
            davanti e' lenta -- REFRESH APP
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default AccountAdminNewTypePicker;
