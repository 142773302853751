import { useEffect } from "react";

function RedirectHandler() {
  useEffect(() => {
    const url = new URL(window.location.href);
    const idToken = url.searchParams.get("id_token");

    if (idToken) {
      window.location.href = `com.poopcheckapp:/oauthredirect?id_token=${idToken}`;
    } else {
      document.body.innerText = "No token found.";
    }
  }, []);

  return <div>Redirecting...</div>;
}

export default RedirectHandler;
